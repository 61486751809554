import { motion } from 'framer-motion';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import logo from '../assets/logoWhite.svg';
import cropdai from '../assets/logoVertical.svg';

export function Preloader() {
  return (
    <Box
      key="preloader"
      flex
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      sx={{
        zIndex: 100,
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: '#151515',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      component={motion.div}
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box sx={{
        width: { xs: '70vmin', md: '60vmin', lg: '50vmin' },
        height: { xs: '70vmin', md: '60vmin', lg: '50vmin' },
        borderRadius: '100%',
        opacity: 0.63,
        background: `linear-gradient(271deg, #11356A 36.61%, #D73BC7 106.23%)`,
        filter: `blur(74px)`,
        transform: `rotate(134.23deg)`,
        zIndex: 100,
        position: 'fixed',
      }}></Box>

      <Box sx={{
        width: '100vw',
        zIndex: 101,
      }}>
        <Box
          sx={{
            mt: { xs: '4vmin', md: '2vmin', lg: '2vmin' },
            height: { xs: '24vmin', md: '18vmin', lg: '16vmin' }
          }}>
          <img
            src={cropdai}
            style={{ height: '100%', width: 'auto' }}
            alt="loading"
          />
        </Box>
      </Box>
    </Box >
  );
}