import { atom, selector } from 'recoil';

export const topUpAmountState = atom({
  key: 'topUpAmountState',
  default: 100
});

// auth states
export const tokenState = atom({
  key: 'tokenState',
  default: null,
});
export const accountEmailState = atom({
  key: 'accountEmailState',
  default: '',
});

export const accountState = atom({
  key: 'accountState',
  default: null,
});

// ***********************************
// appearance
// ***********************************
export const appearanceState = atom({
  key: 'appearanceState',
  default: 'dark',
});
export const savedColorSchemeState = atom({
  key: 'savedColorSchemeState',
  default: 'system',
});

export const bgState = atom({
  key: 'bgState',
  default: 'default',
});
