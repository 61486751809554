import React, { useEffect, useState } from 'react'
// import { motion, AnimatePresence } from 'framer-motion'
import localforage from 'localforage'
// import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField'
// 
// import { Field } from '#page/Auth/components'
// import * as requests from '#src/requests'
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  topUpAmountState,
} from '../state'


export default function TopUpAmount(props) {
  // const { t } = useTranslation()
  // const theme = useTheme()

  const [topUpAmount, setTopUpAmount] = useRecoilState(topUpAmountState)
  const [amount, setAmount] = useState(100)
  const [valid, setValid] = useState(true)

  const [codeError, setCodeError] = useState('')
  const [showErrors, setShowErrors] = useState(true)
  const [isAwaiting, setIsAwaiting] = useState(false)

  // useEffect(() => {
  //   setCodeError('')
  //   setShowErrors(false)
  // }, [topUpAmount])

  useEffect(() => {
    try {
      const parsed = parseFloat(amount || 1)
      if (parsed !== topUpAmount) {
        setAmount(topUpAmount)
        setCodeError('')
        setValid(true)
        setShowErrors(false)
      }

    } catch (error) {

    }
  }, [topUpAmount])


  useEffect(() => {
    console.log('validateAmount', amount)
    try {
      const parsed = parseFloat(amount)
      if (parsed > 10 && parsed < 100000) {
        setTopUpAmount(parsed)
        setCodeError('')
        setValid(true)
        setShowErrors(false)
      }

      if (parsed < 10) {
        setCodeError('Amount must be at least $10')
        // setTopUpAmount(parsed || 0)
        setShowErrors(true)
        setValid(false)
      } else if (parsed > 100000) {
        setCodeError('Amount must be less than $100,000')
        // setTopUpAmount(100000)
        // setTopUpAmount(parsed)
        setShowErrors(true)
        setValid(false)
      }
    } catch (err) {
      setCodeError('Invalid amount')
      // setAmount(value)
      setValid(false)
      setShowErrors(true)
    }
  }, [amount, setTopUpAmount])

  return (
    <Box
      elevation={0}
      sx={{
        py: 2,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            flex: '1 1 50%',
            // mr: 2,
          }}
        >
          <Field
            // disabled={!account || !!isAwaiting}
            autoComplete='off'
            color={valid ? 'success' : 'error'}
            value={amount}
            setValue={setAmount}
            label={`Top up amount (USD)`}
            placeholder={`$1000`}
            errorMessage={codeError}
            showErrors={showErrors}
          />
        </Box>
      </Box>

    </Box>
  )
}


export function Field(params) {
  const {
    disabled,
    color,
    autoComplete,
    inputProps,
    value,
    setValue,
    label,
    type,
    placeholder,
    errorMessage,
    showErrors,
  } = params

  // const theme = useTheme()
  // const defaultInputProps = {
  //   style: {
  //     WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`
  //   }
  // }

  return (
    <TextField
      disabled={disabled}
      color={color}
      inputProps={inputProps}
      autoComplete={autoComplete}
      fullWidth
      variant='outlined'
      type={type}
      value={value}
      onChange={event => setValue(event.target.value)}
      error={showErrors && !!errorMessage}
      label={label || placeholder}
      placeholder={placeholder}
      helperText={showErrors && errorMessage}
    />
  )
}