import { motion } from 'framer-motion';
import { Link as RLink, useNavigate } from "react-router-dom";

import { Box, Typography, Link, Button } from '@mui/material';

import TopUpAmount from '../components/TopUpAmount'
import BusinessOffer from '../components/BusinessOffer'

import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  topUpAmountState,
} from '../state'

const tokenPrice = 0.001

export function TopUp() {
  const navigate = useNavigate();

  const [topUpAmount, setTopUpAmount] = useRecoilState(topUpAmountState)

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        flexGrow: 1,
      }}
    >
      <Box sx={{
        position: 'relative',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
      }}>
        <Box width={'100%'}>
          <Box mt={'1vh'} sx={{
            width: '100%',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
            <Typography variant="h4" sx={{
              fontSize: '3.2vmin',
              zIndex: 1,
            }}>
              Top Up Your Account
            </Typography>
          </Box>
        </Box>

        <Box sx={{
          position: 'relative',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          width: '100%',
        }}>
          <Box mt={'2vh'} sx={{
            // width: '60%',
            minWidth: '30vw',
            px: '3vw',
            pb: '1vh',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backdropFilter: 'blur(30px)',
            boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.2)',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}>

            <BusinessOffer />

            <TopUpAmount />

            <Typography variant="body" sx={{
              fontSize: '1vmin',
              zIndex: 1,
            }}>
              You will get {`${Math.floor(topUpAmount / tokenPrice)} tokens`}
            </Typography>

            <Button
              disabled={topUpAmount < 10 || topUpAmount > 100000}
              sx={{
                mt: '1vh',
              }}
              variant="outlined"
              color="inherit"
              onClick={() => {
                navigate('/app')
              }}
            >
              Checkout
            </Button>
          </Box>
        </Box>
      </Box>
    </Box >
  );
}

export default TopUp