import { motion } from 'framer-motion';
import { Box, Typography, Container, Link, Grid, Stack } from '@mui/material';
import cropdai from '../assets/logoVertical.svg';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import { bgState } from '../state';
import { Download } from '../components/Download';
import { ExpertCards } from '../components/ExpertCards';
import { FAQ } from '../components/FAQ';

import longScreen from '../assets/longscreen2.png';

export function Landing() {
  const setBG = useSetRecoilState(bgState);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        mb: '6vh'
      }}>
      <Container sx={{
        mt: '6vh',
        flexGrow: 1,
      }}>
        <Grid
          container
          spacing={1}
          justifyContent={'space-evenly'}>

          <Grid item xs={12} sm={7}>
            <Stack
              direction="column"
              alignItems={'flex-start'}
              sx={{
                color: 'text.primary',
              }}>
              <Typography
                variant="h3"
                mb={4}
                textAlign={'left'}
                fontWeight={'bold'}>
                Start your ultimate AI experience now
              </Typography>
              <Typography
                variant="h6"
                mb={1}
                textAlign={'left'}>
                Forget subscriptions and hidden fees. With our pay-as-you-go token system, you only pay for what you use. Enjoy unlimited chatting and image generation without any restrictions.
              </Typography>
              <Typography
                variant="h6"
                mb={1}
                textAlign={'left'}>
                Explore AI personalities that match your charm. Engage in witty banter, share interests, and enjoy a new level of digital companionship.
              </Typography>
              <Typography
                variant="h6"
                mb={1}
                textAlign={'left'}>
                Foster a unique friendship with AI. Share thoughts, seek advice, or simply enjoy a casual chat.
              </Typography>
              <Typography
                variant="h6"
                mb={1}
                textAlign={'left'}>
                From travel tips to tech insights, our AI experts are here to share knowledge and offer guidance whenever you need it.
              </Typography>

              <Box sx={{ width: '100%', mt: '3vh' }}>
                <Download />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={5} sx={{
            display: { xs: 'none', sm: 'flex' },
            justifyContent: 'center',
          }}>
            <Box
              component="img"
              sx={{
                // height: { xs: '12vmin', sm: '8vmin', md: '6vmin', xl: '6vmin' },
                width: { xs: '12vmin', sm: '90%', md: '60%', xl: '50%' },
              }}
              src={longScreen}
              alt='Get on Google Play'
            />
          </Grid>
        </Grid>
      </Container>

      <Box sx={{
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        mt: '6vh',
      }}>
        <ExpertCards />
      </Box>

      <Container sx={{
        mt: '6vh',
        flexGrow: 1,
      }}>
        <FAQ />
      </Container>
    </Box >
  );
}

export default Landing;
